import React from 'react';
import Theme from '../../Theme';
import {Menu} from '@styled-icons/boxicons-regular/Menu';
import * as AppActions from '../../AppActions';

export function HeadingLabel(props) {
  const {title, style = {}} = props;

  return (
    <div
      style={{
        ...styles.row,
        ...{justifyContent: 'center', marginBottom: 15},
        ...style,
      }}>
      <div style={styles.headingText}>{title || '---'}</div>
    </div>
  );
}

export function PromoCatItem(props) {
  const {promoCat = {}, bgColor} = props;

  return (
    <div style={{flex: 1}}>
      <div
        style={{...styles.catItemBox, ...{backgroundColor: bgColor}}}
        onClick={async () => {
          AppActions.navigate(`/products/?cat=${promoCat.subcat}`);
        }}>
        <img src={promoCat.image} alt="promo cat" />
        <div style={styles.catItemText}>{promoCat.name || '---'}</div>
      </div>
    </div>
  );
}

export function EventItem(props) {
  const {event} = props;

  return (
    <a
      style={{...styles.row, ...styles.eventItem}}
      href={event.web_url}
      rel="noreferrer"
      target="_blank"
      onClick={(e) => {
        if (!event.web_url) {
          e.preventDefault();
          return;
        }

        if (event.web_url[0] === '/') {
          e.preventDefault();
          AppActions.navigate(event.web_url);
        }
      }}>
      <img src={event.image} alt="event" />
      <div
        style={{
          flex: 1,
          alignSelf: 'stretch',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          marginLeft: 20,
          color: 'var(--text-color)',
        }}>
        <div style={{fontSize: 16, marginBottom: 6}}>{event.date}</div>
        <div style={{fontSize: 20}}>{event.name}</div>
      </div>
    </a>
  );
}

export function ExtraItem(props) {
  const {extraItem = {}} = props;

  return (
    <a
      style={styles.moreItemBox}
      href={extraItem.web_url}
      rel="noreferrer"
      target="_blank"
      onClick={(e) => {
        if (!extraItem.web_url) {
          e.preventDefault();
          return;
        }

        if (extraItem.web_url[0] === '/') {
          e.preventDefault();
          AppActions.navigate(extraItem.web_url);
        }
      }}>
      <img src={extraItem.image} alt="extra" />
      <div style={{padding: '15px 0'}}>
        <div style={styles.moreItemText}>{extraItem.name}</div>
      </div>
    </a>
  );
}

const styles = {
  wrapper: {
    flex: 1,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
  },
  bannerImg: {
    width: 220,
    height: 220,
    alignSelf: 'center',
    marginBottom: 20,
  },
  catItemBox: {
    borderRadius: 10,
    backgroundColor: 'rgb(251, 249, 241)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 10,
    cursor: 'pointer',
  },
  catItemText: {
    marginTop: 10,
    marginBottom: 10,
    fontSize: 20,
    color: '#fff',
  },
  eventItem: {
    marginBottom: 10,
    backgroundColor: Theme.colors.bgYellow,
    borderRadius: 10,
    overflow: 'hidden',
    boxShadow: '0px 2px 6px -1px #555',
    marginBottom: 20,
  },
  headingText: {
    fontSize: 30,
    margin: '10px 0',
    fontWeight: 'bold',
  },
  moreItemBox: {
    display: 'flex',
    flexDirection: 'column',
  },
  moreItemText: {
    textAlign: 'center',
    fontSize: 20,
    color: 'var(--text-color)',
  },
};
