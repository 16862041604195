import React from 'react';
import styled from 'styled-components';
import * as Antd from 'antd';
import {useOutlet} from 'reconnect.js';
import Theme from '../../Theme';
import TodayOrder from '../../Components/TodayOrder';
import {HeadingLabel, PromoCatItem, EventItem, ExtraItem} from './HomeWidgets';
import HeroBanner from './HeroBanner';
import BannerCarousel from './BannerCarousel';
import * as TodayOrderUtil from '../../Utils/TodayOrderUtil';
import PromoAppModal, {usePromoAppModal} from '../../Components/PromoAppModal';

export default function Landing(props) {
  const [_localOrders] = useOutlet('LocalOrders');
  const [landing] = useOutlet('landing');
  const [categories] = useOutlet('categories');
  const [orderIdx, setOrderIdx] = React.useState(0);

  const localOrders = TodayOrderUtil.filterTodayOrder(
    _localOrders.slice().reverse(),
  );

  const bannerItems = landing?.app_banner;
  const [promoCat1, promoCat2, promoCat3] = landing?.app_cat || [];
  const eventItems = landing?.app_event || [];
  const [extraItem1, extraItem2, extraItem3] = landing?.app_extra || [];

  const {cat1, cat2, cat3} = React.useMemo(() => {
    let cat1Name = (promoCat1?.subcat || '').split('-')[0];
    let cat2Name = (promoCat2?.subcat || '').split('-')[0];
    let cat3Name = (promoCat3?.subcat || '').split('-')[0];

    return {
      cat1: categories.find((c) => c.name === cat1Name),
      cat2: categories.find((c) => c.name === cat2Name),
      cat3: categories.find((c) => c.name === cat3Name),
    };
  }, [categories, promoCat1, promoCat2, promoCat3]);

  usePromoAppModal();

  return (
    <Wrapper>
      <HeroBanner landing={landing} />

      <div className="carousel">
        <div className="content">
          <BannerCarousel bannerItems={bannerItems} />
        </div>
      </div>

      {localOrders.length > 0 && (
        <>
          <div className="today-order">
            <div className="content">
              {/* <HeadingLabel title={'本日訂單'} /> */}

              <TodayOrder order={localOrders[orderIdx]} key={orderIdx} />

              <div className="order-idx">
                <Antd.Button
                  type="link"
                  onClick={() => {
                    setOrderIdx(
                      (orderIdx + localOrders.length - 1) % localOrders.length,
                    );
                  }}>
                  <img
                    src="/images/icon-chevron-left.png"
                    style={{height: 16}}
                  />
                </Antd.Button>
                <div>{`${orderIdx + 1} / ${localOrders.length}`}</div>
                <Antd.Button
                  type="link"
                  onClick={() => {
                    setOrderIdx(
                      (orderIdx + localOrders.length + 1) % localOrders.length,
                    );
                  }}>
                  <img
                    src="/images/icon-chevron-right.png"
                    style={{height: 16}}
                  />
                </Antd.Button>
              </div>
            </div>
          </div>
        </>
      )}

      <div className="promo-cats">
        <div className="content">
          <PromoCatItem
            navigation={props.navigation}
            promoCat={promoCat1}
            bgColor={cat1?.darktone}
          />
          <div style={{width: 10}} />
          <PromoCatItem
            navigation={props.navigation}
            promoCat={promoCat2}
            bgColor={cat2?.darktone}
          />
          <div style={{width: 10}} />
          <PromoCatItem
            navigation={props.navigation}
            promoCat={promoCat3}
            bgColor={cat3?.darktone}
          />
        </div>
      </div>

      <div className="content" style={{marginBottom: 60}}>
        <HeadingLabel title="近期活動" />
        <div className="promo-events">
          {eventItems.map((event, idx) => {
            return <EventItem key={idx} event={event} />;
          })}
        </div>
      </div>

      <div
        style={{
          marginTop: 40,
          padding: '40px 0px',
          backgroundColor: '#80c1ae',
        }}>
        <HeadingLabel title="更多資訊" />
        <div className="extra-items">
          <ExtraItem extraItem={extraItem1} />
          <ExtraItem extraItem={extraItem2} />
          <ExtraItem extraItem={extraItem3} />
        </div>
      </div>

      <PromoAppModal />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-top: 64px;
  min-height: calc(100vh - 100px);

  & > .carousel {
    margin: 60px auto;

    & > .content {
      display: flex;
      z-index: 1;
      position: relative;
      justify-content: center;
    }
  }

  & > .today-order {
    margin-top: 60px;
    & > .content {
      position: relative;
      z-index: 1;
      margin: 0px auto;
      max-width: 800px;

      & > .order-idx {
        margin-top: 16px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }

      @media (max-width: 800px) {
        padding-right: 10px;
        padding-left: 10px;
      }
    }
  }

  & .promo-cats {
    & > .content {
      padding-top: 40px;
      padding-bottom: 40px;
      margin: 0 auto;
      max-width: 800px;
      display: flex;

      & > * {
        & img {
          width: 200px;
          height: 200px;
          object-fit: contain;
        }
      }

      @media (max-width: 800px) {
        padding-right: 10px;
        padding-left: 10px;
        & > * {
          & img {
            width: 96px;
            height: 96px;
          }
        }
      }
    }
  }

  & > .content {
    max-width: 800px;
    margin: 0 auto;

    & .promo-events {
      & > * {
        transition: all 200ms;

        & > img {
          width: 200px;
          height: 200px;
          object-fit: contain;
        }
      }

      @media (max-width: 800px) {
        padding-right: 10px;
        padding-left: 10px;
        & > * {
          & > img {
            width: 120px;
            height: 120px;
          }
        }
      }
    }
  }

  & .extra-items {
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    & > * {
      margin: 20px;

      & > img {
        width: 200px;
        height: 200px;
        object-fit: contain;
      }
    }

    @media (max-width: 800px) {
      & > * {
        margin: 10px;

        & > img {
          width: 100px;
          height: 100px;
        }
      }
    }
  }

  @media (max-width: 600px) {
    & > .content {
      & > img.banner {
        width: 256px;
        height: 256px;
      }
    }
  }
`;
