import React from 'react';
import styled from 'styled-components';
import {ShoppingBag} from '@styled-icons/boxicons-regular/ShoppingBag';
import * as AppActions from '../../AppActions';
import {OrderDisplayHelper} from '../../Utils/OrderUtil';
import Theme from '../../Theme';

export default function TodayOrder(props) {
  const {order} = props;
  const [visible, setVisible] = React.useState(false);
  const helper = new OrderDisplayHelper(order);

  React.useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, 100);
  }, []);

  return (
    <Wrapper
      visible={visible}
      onClick={() =>
        AppActions.navigate(`/order/?order_number=${order.order_number}`)
      }>
      <div style={{display: 'flex', alignItems: 'center', padding: 15}}>
        <img src="/images/icon-flash.png" style={styles.icon} />

        <div
          style={{
            fontSize: 18,
            padding: '0 10px',
          }}>
          請於取餐時間前往門市
        </div>
        {helper.renderOrderTypeLabel()}
      </div>

      <div style={{height: 1, backgroundColor: '#ccc'}} />

      <div style={{...styles.content, ...{display: 'flex'}}}>
        <div style={{flex: 1}}>
          <div style={{fontSize: 16, lineHeight: 1.5}}>
            分店: {order.store.name}
          </div>
          <div style={{fontSize: 16, lineHeight: 1.5}}>
            日期: {helper.pickupDate()} {helper.pickupTime()}
          </div>
          <div style={{fontSize: 16, lineHeight: 1.5}}>
            取餐號: 手機末三碼{helper.pickupNumber()}
          </div>
        </div>

        <div
          style={{
            ...Theme.tag,
            alignSelf: 'flex-end',
            borderColor: Theme.colors.main,
            color: Theme.colors.main,
            padding: '6px 12px',
          }}>
          查看訂單
        </div>
      </div>
    </Wrapper>
  );
}

const styles = {
  icon: {
    width: 24,
    height: 24,
    objectFit: 'contain',
  },
  content: {
    padding: 15,
    paddingLeft: 48,
    flex: 1,
  },
  roundBtn: {
    width: 64,
    height: 64,
    borderRadius: 32,
    backgroundColor: Theme.colors.main,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const Wrapper = styled.div`
  border-radius: 15px;
  background-color: ${Theme.colors.bgYellow};
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transform: translateX(${(props) => (props.visible ? '0px' : '100%')});
  transition: all 800ms;
  cursor: pointer;
  box-shadow: ${Theme.shadow};
  &:hover {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);
  }
`;
