import React from 'react';
import styled from 'styled-components';
import * as AppActions from '../../AppActions';
import {LeftArrow} from '@styled-icons/boxicons-solid/LeftArrow';

export default function HeroBanner(props) {
  const {landing} = props;

  return (
    <Wrapper landing={landing}>
      <div className="bg" />
      <div className="content">
        <div className="inner">
          <h2>拉亞漢堡</h2>
          <button
            className="cta"
            style={{margin: '20px 0 40px 0'}}
            onClick={() => {
              AppActions.navigate('/products/?showMenu=true');
            }}>
            線上立即點
            <LeftArrow
              size={24}
              color="black"
              style={{
                transform: 'rotate(-180deg)',
                marginLeft: 5,
                marginTop: -3,
              }}
            />
          </button>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  height: 720px;
  position: relative;
  background-color: rgb(253, 237, 186);

  @keyframes heartbeat {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(0.95);
    }

    100% {
      transform: scale(1);
    }
  }

  & > .bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: no-repeat center/cover
      url('${(props) => props.landing?.hero_banner_image || ''}');
  }

  & button.cta {
    outline: none;
    border: none;
    border-radius: 16px;
    background-color: white;
    font-size: 1.66rem;
    padding: 10px 40px;
    cursor: pointer;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.6);
    animation: heartbeat 1.6s infinite;
  }

  & > .content {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;

    & > .inner {
      max-width: 1280px;
      height: 100%;
      margin: 0 auto;
      padding: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;

      & h2 {
        color: #ddd;
        font-size: 3.75rem;
        text-shadow: -1.5px 0 black, 0 1.5px black, 1.5px 0 black,
          0 -1.5px black;
      }
    }

    @media (max-width: 768px) {
      & > .inner {
        & h2 {
          font-size: 2.5rem;
        }
      }
    }
  }
`;
